import { Controller } from "@hotwired/stimulus"

const SVG = `
  <svg  xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="18" height="18" viewBox="0,0,256,256">
    <g fill="#3b82f6" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(10.66667,10.66667)"><path d="M7,3c-2.197,0 -4,1.803 -4,4v10c0,2.197 1.803,4 4,4h10c2.197,0 4,-1.803 4,-4v-10c0,-2.197 -1.803,-4 -4,-4zM7,5h10c1.117,0 2,0.883 2,2v10c0,1.117 -0.883,2 -2,2h-10c-1.117,0 -2,-0.883 -2,-2v-10c0,-1.117 0.883,-2 2,-2zM15.99219,6.28125c-0.07719,0.00081 -0.15538,0.00955 -0.23438,0.0293l-6,1.5c-0.445,0.111 -0.75781,0.5117 -0.75781,0.9707v5.21875h-1c-1,0 -2,0.97 -2,2c0,1.04 1.12,2 2.5,2c1.38,0 2.5,-0.84086 2.5,-1.88086c0,-0.04 0.00023,-0.07914 -0.00977,-0.11914h0.00977v-5.5l4,-1v3.5h-1c-1,0 -2,0.97 -2,2c0,1.04 1.12,2 2.5,2c1.38,0 2.5,-0.84086 2.5,-1.88086c0,-0.04 0.00023,-0.07914 -0.00977,-0.11914h0.00977v-7.71875c0,-0.56963 -0.46747,-1.00565 -1.00781,-1z"></path></g></g>
  </svg>    
`

export default class extends Controller {
  static targets = ["currentlyPlaying"]


  connect() {
    this.fetchNowPlaying();
  }

  async fetchNowPlaying() {
    try {
      const tokenRes = await fetch("/.netlify/functions/refresh")
      const tokenData = await tokenRes.json()
      const newAccessToken = tokenData.access_token

      const res = await fetch("https://api.spotify.com/v1/me/player/currently-playing", {
        headers: {
          Authorization: `Bearer ${newAccessToken}`
        }
      })
      const data = await res.json()
      const song = data?.item?.name
      const artists = data?.item?.artists?.map(artist => artist.name).join(", "); 

      if (song && artists) {
        this.insertHtml(song, artists);
      };
      
    } catch (error) {
      console.error("Something went wrong:", error)
    }
  }

  insertHtml(song, artists) {
    this.currentlyPlayingTarget.classList.add('absolute', 'bottom-0', 'sticky', 'w-full', 'bg-green-300', 'p-2', 'z-50')

    this.currentlyPlayingTarget.innerHTML = `
      <div class="flex justify-center items-center gap-2">
        <span>${SVG}</span>
        <span>Currently playing: ${song} by ${artists}</span>
      </div>
    `;    
  }
}
